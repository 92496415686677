import get from 'lodash/get';
import reduce from 'lodash/reduce';

import {
  ARTIST_TYPE_ID,
  AUDIENCE_TYPE_ID,
  CASTING_TYPE_ID,
  ACADEMICS_TYPE_ID,
  JOURNALIST_TYPE_ID,
  AGENT_TYPE_ID,
  AOS_TYPE_ID,
} from 'constants/consts';
import route from 'constants/routes';
import { getShortProfessionDetails } from 'utils/artistUtils';
import { getRouteWithSlug } from 'utils/common';
import { TP, BASE_PAGE_ROUTES, ENTITY_TYPE, COMPANY_TYPE_ID, AGENCY_TYPE_ID } from 'constants/index';

export const getProfileName = profile => {
  const profileTypeId = profile?.profileType?.id;

  switch (profileTypeId) {
    case ARTIST_TYPE_ID:
    case AUDIENCE_TYPE_ID:
    case CASTING_TYPE_ID:
    case ACADEMICS_TYPE_ID:
    case JOURNALIST_TYPE_ID:
      return profile?.name;
    case AGENT_TYPE_ID:
      return get(profile, 'agencies[0].name');
    case AOS_TYPE_ID:
      return profile?.organizations?.[0]?.organization?.name;

    default:
      return profile?.name;
  }
};

export const getProfilePhotoURL = profile =>
  get(profile, 'image.small', '') ||
  get(profile, 'organizations[0].organization.image.small', '') ||
  get(profile, 'agencies[0].image.small', '');

export const getProfileSubTitle = profile => {
  const profileTypeId = get(profile, 'profileType.id');

  switch (profileTypeId) {
    case ARTIST_TYPE_ID: {
      const profession = getShortProfessionDetails(profile);
      return profession;
    }
    case AUDIENCE_TYPE_ID:
    case ACADEMICS_TYPE_ID:
    case JOURNALIST_TYPE_ID:
      return profile.profileType.name;

    case AGENT_TYPE_ID:
      return profile.profileType.name;
    case AOS_TYPE_ID:
      return get(profile, 'organizations[0].organization.country.name', '');
    default:
      return '';
  }
};

export const getProfilesByTypes = profiles => {
  const profileByTypes = reduce(
    profiles,
    (acc, profile) => {
      if (profile?.profileType?.id === AOS_TYPE_ID) {
        return { ...acc, organization: [...get(acc, 'organization', []), profile] };
      }

      if (!acc?.[profile?.profileType?.id]) {
        return { ...acc, [profile?.profileType?.id]: [profile] };
      }

      return { ...acc, [profile?.profileType?.id]: [...acc[profile?.profileType?.id], profile] };
    },
    {},
  );

  return profileByTypes;
};

// TODO - remove below method and will use getUpdateProfilePath
export const getProfilePath = (profile, getLinkProps, isEdit = false) => {
  const profileTypeId = get(profile, 'profileType.id');

  switch (profileTypeId) {
    case ARTIST_TYPE_ID:
      return getLinkProps({ entity: profile, entityType: ENTITY_TYPE.ARTIST, pro: false, ...(isEdit && { edit: isEdit }) })?.as;
    case AGENT_TYPE_ID:
      return getRouteWithSlug(route.MANAGERS, get(profile, 'agencies[0]'));
    case AOS_TYPE_ID:
      return getLinkProps({
        entity: get(profile, 'organizations[0].organization'),
        entityType: ENTITY_TYPE.ORGANIZATION,
      })?.as;
    case CASTING_TYPE_ID:
      return route.CASTING_TOOL;
    case ACADEMICS_TYPE_ID:
    case JOURNALIST_TYPE_ID:
      return route.PRODUCTIONS;
    default:
      return getLinkProps()?.as;
  }
};

export const getUpdateProfilePath = profile => {
  const profileTypeId = get(profile, 'profileType.id');

  switch (profileTypeId) {
    case ARTIST_TYPE_ID:
      return {
        entityType: ENTITY_TYPE.ARTIST,
        entity: profile,
        pro: false,
      };
    case AGENT_TYPE_ID:
      return {
        entityType: ENTITY_TYPE.MANAGER,
        entity: profile?.agencies?.[0],
      };
    case AOS_TYPE_ID:
      return {
        entityType: ENTITY_TYPE.ORGANIZATION,
        entity: profile?.organizations?.find(item => item?.organization?.slug?.trim()?.length > 0)?.organization,
      };
    case CASTING_TYPE_ID:
      return { baseRoute: BASE_PAGE_ROUTES.CASTING };
    default:
      return {};
  }
};

export const showNotificationIcon = activeProfile =>
  activeProfile?.profileType?.id === ARTIST_TYPE_ID ||
  (activeProfile?.profileType?.id === AOS_TYPE_ID && activeProfile?.organizations?.[0]?.organization?.id);

export const getProfileTypeEditLabel = profileTypeId => {
  switch (profileTypeId) {
    case COMPANY_TYPE_ID:
      return `${TP}.UPDATE_SCHEDULE`;
    case ARTIST_TYPE_ID:
      return `${TP}.UPDATE_PROFILE`;
    case AGENCY_TYPE_ID:
      return `${TP}.UPDATE_ROSTER`;
    default:
      return `${TP}.UPDATE_PROFILE`;
  }
};

export const getGiftSubscriptionEnabled = ({ profileSubscriptions, profile }) =>
  profileSubscriptions?.length > 0 && profile?.profileType?.id === ARTIST_TYPE_ID;

export const getProfileErrors = ({ profile, profileIdsPendingAccess }) => {
  const isPendingVerification = profile?.validationStatus?.slug === 'pending';
  const isPendingAccess = profileIdsPendingAccess.includes(profile?.id);
  // TODO REG FIX pending registration, change check to check for completed date
  // const isPendingRegistration = registration?.step < REGISTRATION_STEPS.finished;
  const errors = { isPendingVerification, isPendingAccess, isPendingRegistration: false };

  return {
    ...errors,
    isShowErrorIcon: Object.values(errors).some(val => val === true),
  };
};
