import { getRouteWithSlug } from 'utils/globals/app';
import { createDate } from 'utils/date';

import { CHART_TYPES, INSIGHTS_TYPES, DATE_FORMATS, TP, POPUP_TYPES, POPUP_SOURCES } from 'constants/index';

import { CHECKLIST_ITEMS } from 'constants/consts';
import route from 'constants/routes';

export const getArtistRoute = artistProfile => getRouteWithSlug(route.ARTISTS, artistProfile);

export const getProfileProfession = profile => {
  let primaryProfession = profile?.professions?.find(prof => prof?.isPrimary);
  primaryProfession = primaryProfession ? [primaryProfession?.profession?.name] : undefined;
  const secProfessions = profile?.professions
    ?.filter(prof => !prof?.isPrimary)
    ?.reduce((result, prof) => [...result, prof?.profession?.name], []);

  let profileProfession = [];

  if (primaryProfession?.length > 0) {
    profileProfession = primaryProfession;
  }

  if (secProfessions?.length > 0) {
    profileProfession.push(...secProfessions);
  }

  return profileProfession.join(', ');
};

export const getArtisLabelNameWithRole = profile => {
  const profession = getProfileProfession(profile);
  return profession
    ? `${profile?.fullName?.length > 0 ? profile?.fullName : profile?.name} (${profession})`
    : profile?.name;
};

const getPremiumSubscriptionDates = markers =>
  markers
    .filter(marker => marker.key === 'ACTIVE_PREMIUM_SUBSCRIPTION')
    .map(marker => ({
      premiumStartedMinusOneMonth: createDate(marker.from)
        .subtract(1, 'month')
        .format(DATE_FORMATS.FULL_DATE),
      premiumEndedDate: createDate(marker.to).format(DATE_FORMATS.FULL_DATE),
    }));

export const processImpressionData = impressionData => {
  if (!impressionData || !impressionData?.[0] || !impressionData?.[0]?.stats?.timeseries) {
    return {};
  }

  const timeSeriesData = impressionData[0].stats.timeseries;
  const { markers } = impressionData[0].stats;
  const allMonths = timeSeriesData.map(item => createDate(item.date).format(DATE_FORMATS.MONTH_YEAR));

  const premiumPeriods = getPremiumSubscriptionDates(markers);
  const productUpdateMarkers = markers
    .filter(marker => marker.key === 'PRODUCT_UPDATE')
    .map(marker => ({
      ...marker,
      date: createDate(marker.date).format(DATE_FORMATS.MONTH_YEAR),
    }));

  const { lineData, premiumLineData, premiumDates } = timeSeriesData.reduce(
    (acc, item) => {
      const { date, value: count } = item;
      const month = createDate(date).format(DATE_FORMATS.MONTH_YEAR);

      acc.lineData[month] = count;

      const isInPremiumPeriod = premiumPeriods.some(
        period => date >= period.premiumStartedMinusOneMonth && date <= period.premiumEndedDate,
      );

      if (isInPremiumPeriod) {
        acc.premiumLineData[month] = count;
        if (!acc.premiumDates.includes(month)) {
          acc.premiumDates.push(month);
        }
      }

      return acc;
    },
    { lineData: {}, premiumLineData: {}, premiumDates: [] },
  );
  return { premiumLineData, premiumDates, allMonths, lineData, productUpdateMarkers };
};

export const sumObjectValues = obj => {
  if (!obj || typeof obj !== 'object') {
    return 0;
  }
  return Object.values(obj).reduce((sum, value) => (typeof value === 'number' ? sum + value : sum), 0);
};

export const createChartConfig = (
  metricData,
  metricType,
  hasInsightsPermission,
  barGraphData,
  isChurned,
  isActiveSubscription,
  productionCount,
  trackingComponent,
) => {
  const barData = barGraphData?.data?.data?.[0]?.stats?.bargraph;
  const { premiumLineData, premiumDates, allMonths, lineData, productUpdateMarkers } = processImpressionData(
    metricData?.data?.data || [],
  );

  const lines = [
    {
      type: 'linear',
      stroke: '#383838',
      activeDot: { r: 3 },
      data: lineData,
    },
    {
      type: 'linear',
      stroke: '#F36D2A',
      data: premiumLineData,
    },
  ];

  const areas = [
    {
      type: 'monotone',
      fill: '#FFF2EB',
      stroke: '#FFF2EB',
      data: premiumDates,
    },
  ];

  const referenceLines = productUpdateMarkers?.map(marker => ({
    x: marker?.date,
    info: marker?.info,
    stroke: '#7F0181',
    label: '',
    strokeDasharray: '3 3',
  }));

  const impressionCount = sumObjectValues(lineData);

  const chartDescription = () => {
    if (metricType === INSIGHTS_TYPES.IMPRESSIONS) {
      return `INSIGHTS_IMPRESSION_DESC`;
    }
    if (metricType === INSIGHTS_TYPES.PAGEVIEWS) {
      return `INSIGHTS_PAGEVIEW_DESC`;
    }
    if (metricType === INSIGHTS_TYPES.SEARCH_APPEARANCES) {
      return `INSIGHTS_SEARCH_APPEARANCES_DESC`;
    }
    return '';
  };

  const chartBanner = () => {
    if (impressionCount) {
      return {};
    }

    if (metricType === INSIGHTS_TYPES.IMPRESSIONS) {
      if (isActiveSubscription || !productionCount) {
        return {
          bannerTitle: `UP_UPDATE_PERFORMANCES`,
          bannerSection: POPUP_SOURCES.IMPRESSIONS,
          bannerActionText: !productionCount ? 'CL_ADD_PERFORMANCE_CTA' : 'CL_UPDATE_PERFORMANCE_CTA',
          bannerActionCTA: !productionCount ? CHECKLIST_ITEMS.PRODUCTIONS : CHECKLIST_ITEMS.UPDATE_PRODUCTIONS,
          insightType: INSIGHTS_TYPES.VIEW_IMPRESSIONS,
        };
      }
      return {
        bannerTitle: isChurned ? `UP_REACTIVATE_ARTIST_TO_PROCEED` : `UP_START_ARTIST_TRIAL_TO_PROCEED`,
        bannerSection: POPUP_SOURCES.IMPRESSIONS,
        bannerActionCTA: isChurned ? POPUP_TYPES.CHURNED : POPUP_TYPES.UPGRADE,
        bannerActionText: isChurned ? 'CL_REACTIVATE_TO_PREMIUM' : 'CL_UPGRADE_TO_PREMIUM',
        insightType: INSIGHTS_TYPES.VIEW_IMPRESSIONS,
      };
    }
    if (metricType === INSIGHTS_TYPES.PAGEVIEWS) {
      if (isActiveSubscription || !productionCount) {
        return {
          bannerTitle: !productionCount ? 'UP_UPDATE_PERFORMANCES' : `UP_INCREASE_PAGE_VIEWS_UPDATE_PERFORMANCES`,
          ...(!productionCount && { bannerSection: POPUP_SOURCES.PAGE_VIEW_INSIGHTS }),
          bannerActionText: !productionCount ? 'CL_ADD_PERFORMANCE_CTA' : 'CL_UPDATE_PERFORMANCE_CTA',
          bannerActionCTA: !productionCount ? CHECKLIST_ITEMS.PRODUCTIONS : CHECKLIST_ITEMS.UPDATE_PRODUCTIONS,
          insightType: INSIGHTS_TYPES.ACCESS_PAGE_VIEWS,
        };
      }
      return {
        bannerTitle: isChurned ? `UP_REACTIVATE_ARTIST_TO_PROCEED` : `UP_START_ARTIST_TRIAL_TO_PROCEED`,
        bannerSection: POPUP_SOURCES.PAGE_VIEW_INSIGHTS,
        bannerActionCTA: isChurned ? POPUP_TYPES.CHURNED : POPUP_TYPES.UPGRADE,
        bannerActionText: isChurned ? 'CL_REACTIVATE_TO_PREMIUM' : 'CL_UPGRADE_TO_PREMIUM',
        insightType: INSIGHTS_TYPES.ACCESS_PAGE_VIEWS,
      };
    }
    if (metricType === INSIGHTS_TYPES.SEARCH_APPEARANCES) {
      if (isActiveSubscription || !productionCount) {
        return {
          bannerTitle: !productionCount ? `UP_UPDATE_PERFORMANCES` : `UP_INCREASE_SEARCH_APPEARANCES_ADD_STAGE_NAME`,
          ...(!productionCount && { bannerSection: POPUP_SOURCES.SEARCH_APPEARANCES_INSIGHTS }),
          bannerActionText: !productionCount ? 'CL_ADD_PERFORMANCE_CTA' : 'CL_UPDATE_PROFILE_INFO_CTA',
          bannerActionCTA: !productionCount ? CHECKLIST_ITEMS.PRODUCTIONS : CHECKLIST_ITEMS.UPDATE_PROFILE_INFO,
          insightType: INSIGHTS_TYPES.VIEW_SEARCH_APPEARANCES,
        };
      }
      return {
        bannerTitle: isChurned ? `UP_REACTIVATE_ARTIST_TO_PROCEED` : `UP_TO_ARTIST_PREMIUM`,
        bannerSection: POPUP_SOURCES.SEARCH_APPEARANCES_INSIGHTS,
        bannerActionCTA: isChurned ? POPUP_TYPES.CHURNED : POPUP_TYPES.UPGRADE,
        bannerActionText: isChurned ? 'CL_REACTIVATE_TO_PREMIUM' : 'CL_UPGRADE_TO_PREMIUM',
        insightType: INSIGHTS_TYPES.VIEW_SEARCH_APPEARANCES,
      };
    }
    return {};
  };

  const categoryChartBanner = (isCountry, impressions) => {
    if (!impressions && isActiveSubscription) {
      let bannerSection = '';
      let bannerActionText = 'CL_UPDATE_PERFORMANCE_CTA';
      let bannerTitle = 'UP_UPDATE_PERFORMANCES';
      let bannerActionCTA = null;
      switch (metricType) {
        case INSIGHTS_TYPES.IMPRESSIONS:
          bannerSection = POPUP_SOURCES.IMPRESSIONS;
          bannerActionCTA = CHECKLIST_ITEMS.UPDATE_PRODUCTIONS;
          break;
        case INSIGHTS_TYPES.PAGEVIEWS:
          bannerTitle = isCountry ? 'UP_INCREASE_PAGE_VIEWS_BY_COUNTRY' : 'UP_INCREASE_PAGE_VIEWS_BY_CITY';
          bannerActionText = isCountry ? 'UP_UPDATE_INTRO_SHOWREEL' : 'UP_UPDATE_PROFILE_PHOTO';
          bannerActionCTA = isCountry ? CHECKLIST_ITEMS.INTRO_SHOWREEL : CHECKLIST_ITEMS.PROFILE_PHOTO;
          bannerSection = POPUP_SOURCES.IMPRESSIONS;
          break;
        case INSIGHTS_TYPES.SEARCH_APPEARANCES:
          bannerSection = isCountry
            ? POPUP_SOURCES.SEARCH_APPEARANCES_INSIGHTS
            : POPUP_SOURCES.SEARCH_APPEARANCES_INSIGHTS;
          bannerTitle = 'UP_INCREASE_SEARCH_APPEARANCES_BY_CATEGORY_UPDATE_PERFORMANCES';
          bannerActionCTA = CHECKLIST_ITEMS.UPDATE_PRODUCTIONS;
          break;
        default:
          break;
      }

      return {
        bannerTitle,
        bannerSection,
        bannerActionText,
        bannerActionCTA,
      };
    }

    if (metricType === INSIGHTS_TYPES.IMPRESSIONS) {
      return {
        bannerTitle: isChurned ? `UP_REACTIVATE_ARTIST_TO_PROCEED` : `UP_START_ARTIST_TRIAL_TO_PROCEED`,
        bannerSection: POPUP_SOURCES.IMPRESSIONS,
        bannerActionText: isChurned ? 'CL_REACTIVATE_TO_PREMIUM' : 'CL_UPGRADE_TO_PREMIUM',
        bannerActionCTA: isChurned ? POPUP_TYPES.CHURNED : POPUP_TYPES.UPGRADE,
        insightType: isCountry ? INSIGHTS_TYPES.IMPRESSIONS_BY_COUNTRY : INSIGHTS_TYPES.IMPRESSIONS_BY_CITY,
      };
    }
    if (metricType === INSIGHTS_TYPES.PAGEVIEWS) {
      return {
        bannerTitle: isChurned ? `UP_REACTIVATE_ARTIST_TO_PROCEED` : `UP_START_ARTIST_TRIAL_TO_PROCEED`,
        bannerSection: POPUP_SOURCES.PAGE_VIEW_INSIGHTS,
        bannerActionCTA: isChurned ? POPUP_TYPES.CHURNED : POPUP_TYPES.UPGRADE,
        bannerActionText: isChurned ? 'CL_REACTIVATE_TO_PREMIUM' : 'CL_UPGRADE_TO_PREMIUM',
        insightType: isCountry ? INSIGHTS_TYPES.ACCESS_PAGE_VIEWS_BY_COUNTRY : INSIGHTS_TYPES.ACCESS_PAGE_VIEWS_BY_CITY,
      };
    }
    if (metricType === INSIGHTS_TYPES.SEARCH_APPEARANCES) {
      return {
        bannerTitle: isChurned ? `UP_REACTIVATE_ARTIST_TO_PROCEED` : `UP_START_ARTIST_TRIAL_TO_PROCEED`,
        bannerSection: POPUP_SOURCES.SEARCH_APPEARANCES_INSIGHTS,
        bannerActionText: isChurned ? 'CL_REACTIVATE_TO_PREMIUM' : 'CL_UPGRADE_TO_PREMIUM',
        bannerActionCTA: isChurned ? POPUP_TYPES.CHURNED : POPUP_TYPES.UPGRADE,
        insightType: isCountry
          ? INSIGHTS_TYPES.SEARCH_APPEARANCES_BY_COUNTRY
          : INSIGHTS_TYPES.SEARCH_APPEARANCES_BY_CITY,
      };
    }
    return {};
  };

  const createBarChartData = (title, dataSegment, isCountry) => ({
    title,
    ...categoryChartBanner(isCountry, dataSegment),
    type: CHART_TYPES.BAR,
    enableBlur: !hasInsightsPermission,
    data: dataSegment ? Object.entries(dataSegment).map(([label, value]) => ({ label, value })) : [],
  });

  const countryChartData = createBarChartData(`${TP}.m_COUNTRY`, barData?.country, true);
  // const cityChartData = createBarChartData(`${TP}.FN_CITIES`, barData?.city); commenting this for fixing FB-26086 : uncomment once the data is rectified

  const consolidatedBarData = [countryChartData];
  const leftMargin = (() => {
    const values = Object.values(lines?.[0]?.data || {});
    const maxValue = values?.length ? Math.max(...values) : 0;

    switch (true) {
      case maxValue > 100000:
        return 0;
      case maxValue > 1000:
        return -20;
      default:
        return -30;
    }
  })();

  const formattedData = {
    title: metricType,
    description: chartDescription(),
    count: impressionCount,
    trackingComponent,
    cardsData: [
      {
        title: '',
        type: CHART_TYPES.COMBO,
        xValues: allMonths,
        lines,
        areas,
        chartTitle: metricType,
        referenceLines,
        customTooltip: true,
        toolTipLabel: metricType,
        premiumStarted: '', // TODO: API need to send this data as premium last activated
        enableBlur: !hasInsightsPermission,
        ...chartBanner(),
        config: {
          margin: { top: 20, right: 5, bottom: 0, left: leftMargin },
          width: '100%',
          height: 240,
          mobileHeight: 170,
        },
        formatXAxis: tick => tick.slice(0, 3),
      },
      ...consolidatedBarData,
    ],
  };
  return formattedData;
};
