/* eslint-disable import/no-cycle */
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import DrawerMui from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';

import Typography from '../Typography';
import classes from './Drawer.module.scss';
import LinkButton from '../LinkButton';
import SpriteIcon from '../SpriteIcon';

const Drawer = ({
  isOpen,
  title,
  subTitle,
  children,
  onClose,
  className,
  placement = 'bottom',
  leftIcon,
  header,
  styles = { drawerRoot: '', drawerPaper: '', backdrop: '', content: '', header: '' },
  hideHeaderBorder = false,
  disableEnforceFocus = false,
  trackingData = {},
}) => (
  <DrawerMui
    disableEnforceFocus={disableEnforceFocus}
    className={styles?.drawerRoot}
    open={isOpen}
    anchor={placement}
    onClose={onClose}
    BackdropProps={{
      className: classnames(classes.backdrop, {
        [styles?.backdrop]: !!styles?.backdrop,
      }),
    }}
    PaperProps={{
      className: classnames(classes.drawer, className, {
        [styles?.drawerPaper]: !!styles?.drawerPaper,
      }),
    }}
  >
    {title && (
      <div className={classes.header}>
        <div
          className={classnames(classes.header_text, {
            [styles?.headerText]: !!styles?.headerText,
          })}
        >
          {leftIcon && (
            <div className={classes.title}>
              <span className={classnames(classes.icon, { [classes.leftIcon]: !!title })}>{leftIcon}</span>
            </div>
          )}
          <div
            className={classnames({
              [classes.header_text_container]: !!leftIcon,
            })}
          >
            <Typography size="18" weight="bold" variant="p" className={classes.title}>
              {title}
            </Typography>

            {subTitle && (
              <Typography size="12" weight="medium" variant="div" className={classes.subTitle}>
                {subTitle}
              </Typography>
            )}
          </div>
        </div>
        {onClose && (
          <LinkButton
            variant="tertiary"
            styles={{
              root: classnames(classes.header_closeIcon, {
                [styles?.headerCloseIcon]: !!styles?.headerCloseIcon,
              }),
            }}
            leftIcon={<SpriteIcon icon="modal_close" size={16} />}
            onClick={onClose}
            trackingData={trackingData}
            disableHover
            disableUnderline
            stopPropagation
            preventDefault
          />
        )}
      </div>
    )}
    {header && (
      <div
        className={classnames(classes.header, {
          [classes.hideHeaderBorder]: !!hideHeaderBorder,
          [styles?.header]: !!styles?.header,
        })}
      >
        {header}
        {onClose && (
          <LinkButton
            variant="tertiary"
            styles={{ root: classes.header_closeIcon }}
            leftIcon={<CloseIcon />}
            onClick={onClose}
            trackingData={trackingData}
          />
        )}
      </div>
    )}
    <div className={classnames(classes.drawer__content, { [styles?.content]: !!styles?.content })}>{children}</div>
  </DrawerMui>
);

Drawer.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.any,
  onClose: PropTypes.func,
  placement: PropTypes.string,
  header: PropTypes.node,
  disableEnforceFocus: PropTypes.bool,
};

export default Drawer;
