import { useMutation } from 'utils/react-query';
import { getLanguage } from 'utils/queriesUtil';
import { transformEntity } from 'utils/globals';
import * as API from 'utils/API';
import { transformImagesWithNoIndex } from 'utils/globals/transformers';
import { agencyRowMapping } from 'utils/artists';

import {
  USER_NEW_PERFORMANCES,
  PROFILES_ENDPOINT,
  PROFILE_REPERTOIRE,
  PROFESSIONS,
  PROFILE_CAREER_GRAPH,
  PROFILE_CHECKLIST,
  PROFILE_CUSTOMIZATIONS,
  PROFILE_PREFERRED_ENTITIES,
  SUGGESTED_ENTITIES,
  PROFILES_WITH_SIMILAR_PROFESSION_ENDPOINT,
  PROFILE_CONTACTS_ENDPOINT,
  PROFILE_DOCUMENTS,
  DELETE_PROFILE_DOCUMENT,
} from 'constants/endpointConstants';
import { PARENT_PROFESSION_SLUG, PROFESSIONS_AVAILABILITY, TAG_TYPE, VALIDATION_STATUS } from 'constants/consts';
import { ENTITY_TYPE, PROFILE_TYPE_IDS, STATUS, PROFILE_AGGREGATION_TYPES, SORT_OPTION_VALUES } from 'constants/index';
import { getOption } from 'utils/globals/app';
import { transformFiltersToParams } from 'utils/globals/filters';
import { deleteQuery, getQuery, postQuery, updateQuery, transformQueryFn } from 'utils/globals/queries';
import { transformGetRandomisedOrderedList, transformListToSingleData } from 'containers/Globals/transformers';
import {
  transformCastingViewRepertoireData,
  transformRepertoireData,
  transformSplitByProfessionType,
} from './transformers';

const { getList, getArtistAgencies, getArtistsAgreements, getInsights, updateOne } = API;
const PROFILE_UPDATE_CHECKLIST_PREFERENCES = ({ id, prefId }) => `${PROFILES_ENDPOINT}/${id}/customizations/${prefId}`;

const defaultConfig = {
  retry: false,
  retryOnMount: false,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 10 * 60 * 1000,
  getNextPageParam: lastPage => {
    const { limit, page, total } = lastPage;
    const totalFetched = limit * page;

    return totalFetched < total ? page + 1 : undefined;
  },
  getPreviousPageParam: () => false,
};

export const CAREER_GRAPH_MODE = {
  ROLE_GRAPH: 'role',
  ORG_GRAPH: 'company',
};

const ENDPOINTS = {
  PROFILE_AGGREGATION: 'profiles/aggregations',
  PROFILE_TRENDING: 'profiles/trending',
  PROFILE_SEARCH: 'profiles/search',
};

const fetchProfileProfessionsAggregation = ({ limit, filters = {} }, cookies) => {
  const params = {
    queryParams: {
      ...filters,
      profile_type_id: PROFILE_TYPE_IDS.ARTIST,
    },
    pagination: {
      limit: limit || 200,
      page: 1,
    },
  };
  return getList(ENDPOINTS.PROFILE_AGGREGATION, params, cookies).then(response => response?.data);
};

const fetchArtist = ({ id, showApprovedHidden = false, asEdit = false }, cookies) => {
  const validationStatus = [VALIDATION_STATUS.APPROVED, VALIDATION_STATUS.PENDING];

  if (showApprovedHidden) {
    validationStatus.push(VALIDATION_STATUS.APPROVED_HIDDEN);
  }

  const params = {
    queryParams: {
      id,
      validation_status: validationStatus,
      ...(asEdit && { as_edit: true }),
    },
  };

  return Promise.allSettled([
    getList(PROFILES_ENDPOINT, params, cookies),
    getList(PROFILE_CUSTOMIZATIONS(id), params, cookies),
  ]).then(results => {
    const data = results?.reduce((acc, result, index) => {
      if (index === 1) {
        return {
          ...acc,
          customization: result?.value?.data?.data,
        };
      }
      return {
        ...result?.value?.data?.data?.[0],
      };
    }, {});

    return transformEntity(data, ENTITY_TYPE.ARTIST);
  });
};

const fetchArtistProfessions = ({ filters = {}, limit = 500, page = 1 }, cookies) => {
  const params = {
    queryParams: {
      ...filters,
      aggregation_type: PROFILE_AGGREGATION_TYPES.PROFESSION,
      profile_type_id: PROFILE_TYPE_IDS.ARTIST,
      sort: SORT_OPTION_VALUES.PROFILES_COUNT_DESC,
    },
    pagination: {
      limit,
      page,
    },
  };

  return getList(ENDPOINTS.PROFILE_AGGREGATION, params, cookies).then(response => response?.data);
};

const fetchRepertoire = async ({ id, filters = {}, asEdit = false }, cookies) => {
  const params = {
    queryParams: { ...filters, ...(asEdit && { as_edit: true, show_hidden: true }) },
    pagination: {
      limit: 9999,
      page: 1,
    },
  };

  return getList(PROFILE_REPERTOIRE(id), params, cookies).then(response => response?.data);
};

const fetchVoiceTypeProfession = async ({ query, limit = 999, filters = {} }, cookies) => {
  const params = {
    queryParams: {
      ...query,
      linked_to_artist: true,
      validation_status: STATUS.APPROVED.VALUE,
      ...filters,
    },
    pagination: {
      limit,
      page: 1,
    },
  };

  return getList(PROFESSIONS, params, cookies);
};

const fetchInstrumentTypeProfession = async ({ query, limit = 999, filters = {} }, cookies) => {
  const params = {
    queryParams: {
      ...query,
      linked_to_artist: true,
      validation_status: STATUS.APPROVED.VALUE,
      ...filters,
    },
    pagination: {
      limit,
      page: 1,
    },
  };

  return getList(PROFESSIONS, params, cookies);
};

const fetchArtists = ({ filters, limit = 10, page }, cookies) => {
  const params = {
    queryParams: {
      ...filters,
      profile_type_id: PROFILE_TYPE_IDS.ARTIST,
    },
    pagination: {
      limit,
      page,
    },
  };

  return getList(PROFILES_ENDPOINT, params, cookies).then(response => response?.data);
};

const fetchArtistsSearch = ({ filters, limit = 5, page }, cookies) => {
  const params = {
    queryParams: {
      ...filters,
      profile_type_id: PROFILE_TYPE_IDS.ARTIST,
    },
    pagination: {
      limit,
      page,
    },
  };

  return getList(ENDPOINTS.PROFILE_SEARCH, params, cookies).then(response => response?.data);
};

const fetchTrendingArtists = ({ filters, limit, page }, cookies) => {
  const params = {
    queryParams: filters,
    pagination: {
      limit,
      page,
    },
  };

  return getList(ENDPOINTS.PROFILE_TRENDING, params, cookies).then(response => response?.data);
};

const fetchArtistsWithSimilarProfessions = ({ id, filters, limit, page }, cookies) => {
  const params = {
    queryParams: {
      ...filters,
    },
    pagination: {
      limit,
      page,
    },
  };

  return getList(PROFILES_WITH_SIMILAR_PROFESSION_ENDPOINT(id), params, cookies).then(response => response?.data);
};

const fetchGraphData = ({ id, filters, isCompanyGraph }, cookies) => {
  const params = {
    queryParams: {
      ...filters,
      graph_type: isCompanyGraph ? CAREER_GRAPH_MODE?.ORG_GRAPH : CAREER_GRAPH_MODE?.ROLE_GRAPH,
    },
  };

  return getList(PROFILE_CAREER_GRAPH(id), params, cookies).then(response => ({
    ...response?.data,
    data: response?.data?.data?.[0]?.graph,
  }));
};

const fetchAgencies = async (id, privacyAttributes, cookies) => {
  const agencyResponse = await getArtistAgencies(
    { artist_profile_id: id, privacy_attribute_id: privacyAttributes },
    {},
    cookies,
  );
  const agencies = agencyResponse?.data?.data || [];

  return agencies;
};

const fetchAgencyAgreements = async (id, privacyAttributes, cookies) => {
  let agreementData = {};

  const agencies = await fetchAgencies(id, privacyAttributes, cookies);
  const agencyIds = agencies?.map(x => x.id);

  if (agencyIds?.length > 0) {
    const agreementResponse = await getArtistsAgreements(agencyIds, {}, cookies);
    const agreements = agreementResponse?.data?.data || [];

    agreementData = {
      agencies,
      agreements,
    };
  }

  return agreementData;
};

const fetchUserPerformanceList = (profileId, userId, cookies) => {
  const params = {
    queryParams: {
      profile_id: profileId,
      user_id: userId,
      validation_status: VALIDATION_STATUS?.PENDING,
    },
  };

  return getList(USER_NEW_PERFORMANCES, params, {}, cookies).then(response => response.data);
};

export const useUpdateArtistChecklistPreferences = (id, options = {}) => {
  const mutationFn = payload => updateOne(PROFILE_CUSTOMIZATIONS(id), payload?.id, payload);

  return useMutation(mutationFn, options);
};

export const fetchSuggestedData = ({ id, entityType, filters, limit = 10 }, cookies) => {
  const params = {
    queryParams: {
      entity_type: entityType,
      ...filters,
    },
    pagination: {
      limit,
    },
  };

  return getList(SUGGESTED_ENTITIES(id), params, {}, cookies).then(response => response?.data);
};

export const fetchFeaturedEntities = ({ id, filters }, cookies) => {
  const params = {
    queryParams: filters,
    pagination: {
      limit: 6,
    },
  };

  return getList(PROFILE_PREFERRED_ENTITIES(id), params, {}, cookies).then(response => response?.data);
};

export const fetchProfessionDetails = ({ filters }, cookies) => {
  const params = {
    queryParams: {
      id: filters?.id,
      slug: filters?.slug,
    },
    pagination: {
      limit: 1,
      page: 1,
    },
  };

  return getList(PROFESSIONS, params, cookies);
};

const fetchProfileDocuments = ({ id, documentType }, cookies) => {
  const params = {
    queryParams: {
      document_type: documentType,
    },
  };

  return getList(PROFILE_DOCUMENTS(id), params, cookies).then(response => response?.data);
};

const queries = {
  getArtists: getQuery('GET_ARTISTS', fetchArtists),
  getArtistsSearch: getQuery('GET_ARTISTS_SEARCH', fetchArtistsSearch),
  getProfileProfessionAggregation: getQuery('GET_PROFILE_PROFESSION_AGGREGATION', fetchProfileProfessionsAggregation),
  getTrendingArtist: getQuery('GET_ARTIST_TRENDING', fetchTrendingArtists),
  getArtistsWithSimilarProfessions: getQuery(
    'GET_ARTISTS_WITH_SIMILAR_PROFESSIONS',
    transformQueryFn(fetchArtistsWithSimilarProfessions, transformImagesWithNoIndex),
  ),
  getArtistDetails: getQuery('GET_ARTIST_DETAILS', fetchArtist),
  getRepertoireDetails: getQuery('GET_REPERTOIRE_DETAILS', fetchRepertoire, props => {
    const baseConfig = {
      ...props,
      filters: transformFiltersToParams(props?.filters),
    };

    if (props?.queryConfig?.select) {
      return baseConfig;
    }
    if (props?.isCastingView) {
      return {
        ...baseConfig,
        queryConfig: {
          ...props?.queryConfig,
          select: response => transformCastingViewRepertoireData(response, props),
        },
      };
    }
    return {
      ...baseConfig,
      queryConfig: {
        ...props?.queryConfig,
        select: response => transformRepertoireData(response),
      },
    };
  }),

  getRoleGraphDetails: ({ id, filters, queryConfig = {} }, cookies, language) => {
    const transformedFilters = transformFiltersToParams(filters);

    return {
      queryKey: [getLanguage(language), 'GET_ROLE_GRAPH', `${id}`, JSON.stringify(transformedFilters)],
      queryFn: async () => fetchGraphData({ id, filters: transformedFilters }, cookies),
      ...defaultConfig,
      ...queryConfig,
    };
  },
  getCompanyGraphDetails: ({ id, filters, queryConfig = {} }, cookies, language) => {
    const transformedFilters = transformFiltersToParams(filters);

    return {
      queryKey: [getLanguage(language), 'GET_COMPANY_GRAPH', `${id}`, JSON.stringify(transformedFilters)],
      queryFn: async () => fetchGraphData({ id, filters: transformedFilters, isCompanyGraph: true }, cookies),
      ...defaultConfig,
      ...queryConfig,
    };
  },
  getArtistAgencies: ({ id, privacyAttributes, queryConfig = {} }, cookies, language) => ({
    queryKey: [getLanguage(language), 'GET_ARTIST_AGENCIES', `${id}`, JSON.stringify(privacyAttributes)],
    queryFn: async () => fetchAgencyAgreements(id, privacyAttributes, cookies),
    select: resp => agencyRowMapping(resp),
    ...defaultConfig,
    ...queryConfig,
  }),
  getUserPerformanceList: ({ profileId, userId, queryConfig = {} }, cookies, language) => ({
    queryKey: [getLanguage(language), 'GET_USER_PERFORMANCE_LIST', `${profileId}`, `${userId}`],
    queryFn: async () => fetchUserPerformanceList(profileId, userId, cookies),
    ...defaultConfig,
    ...queryConfig,
  }),
  getInsights: getQuery('GET_INSIGHTS', getInsights, props => ({
    ...props,
    extraQueryKeys: [props?.metric],
  })),
  getProfessions: (
    {
      hasParentProfession = false,
      userId,
      tags,
      tagType,
      querySearch,
      isSelect,
      limit,
      queryConfig = {},
      professionId,
    },
    cookies,
    language,
  ) => ({
    queryKey: [
      getLanguage(language),
      'PROFESSIONS',
      hasParentProfession,
      tags,
      tagType,
      isSelect,
      querySearch,
      professionId,
    ],
    queryFn: async () => {
      const query = {
        queryParams: {
          user_id: userId,
          tag: tags,
          tag_type: tagType,
          query: querySearch,
          limit: limit || 25,
          id: professionId || '',
          ...(hasParentProfession && {
            parent_profession: `${PARENT_PROFESSION_SLUG.SINGER},${PARENT_PROFESSION_SLUG.INSTRUMENTALIST}`,
          }),
          validation_status: 'pending,approved',
        },
      };

      return getList(PROFESSIONS, query, cookies).then(response => response.data);
    },
    ...defaultConfig,
    ...queryConfig,
  }),
  getProfessionOptions: ({ limit = 100, queryConfig = {} } = {}, cookies, language) => {
    const query = {
      queryParams: { tag_type: TAG_TYPE.AVAILABILITY, tag: PROFESSIONS_AVAILABILITY.GET_PROFILE, limit },
    };

    return {
      queryKey: [getLanguage(language), 'GET_PROFESSION', limit],
      queryFn: async () => getList(PROFESSIONS, query, cookies),
      select: response =>
        response?.data?.data?.map(option => ({
          ...getOption(option, 'id', 'name', { id: option?.id }),
          profession: option,
          slug: option?.slug,
        })) || [],
      ...defaultConfig,
      ...queryConfig,
    };
  },
  getProfessionVoiceTypeOptions: getQuery('GET_VOICE_TYPE_PROFESSION', fetchVoiceTypeProfession, props => ({
    ...props,
    limit: 100,
    queryConfig: {
      ...props?.queryConfig,
      select: response =>
        response?.data?.data?.map(option => ({
          ...option,
          ...getOption(option, 'id', 'name', { id: option?.id }),
          slug: option?.slug,
        })) || [],
    },
  })),
  getProfessionInstrumentTypeOptions: getQuery(
    'GET_INSTRUMENT_TYPE_PROFESSION',
    fetchInstrumentTypeProfession,
    props => ({
      ...props,
      limit: 100,
      queryConfig: {
        ...props?.queryConfig,
        select: response =>
          response?.data?.data?.map(option => ({
            ...option,
            ...getOption(option, 'id', 'name', { id: option?.id }),
            slug: option?.slug,
          })) || [],
      },
    }),
  ),
  getCheckListData: ({ id, limit = 100, queryConfig = {} } = {}, cookies, language) => ({
    queryKey: [getLanguage(language), 'GET_PROFILE_CHECKLIST', id, limit],
    queryFn: async () => getList(PROFILE_CHECKLIST(id), { queryParams: { category: 'onboarding' } }, cookies),
    select: response => response?.data?.data || [],
    ...defaultConfig,
    ...queryConfig,
  }),
  getProfileCustomizations: ({ id, limit = 100, queryConfig = {} } = {}, cookies, language) => ({
    queryKey: [getLanguage(language), 'GET_PROFILE_CUSTOMIZATIONS', id, limit],
    queryFn: async () => getList(PROFILE_CUSTOMIZATIONS(id), '', cookies),
    select: response => response?.data?.data || [],
    ...defaultConfig,
    ...queryConfig,
  }),
  getSuggestedEntities: getQuery(
    'GET_SUGGESTED_ENTITIES',
    transformQueryFn(fetchSuggestedData, transformImagesWithNoIndex),
  ),
  savePreferredEntities: postQuery(PROFILE_PREFERRED_ENTITIES),
  saveCheckListPreferences: updateQuery(PROFILE_UPDATE_CHECKLIST_PREFERENCES),
  getArtistProfessions: getQuery(
    'GET_ARTIST_PROFESSIONS',
    transformQueryFn(fetchArtistProfessions, transformGetRandomisedOrderedList, transformSplitByProfessionType),
  ),
  getProfessionDetails: getQuery(
    'GET_PROFESSION_DETAILS',
    transformQueryFn(fetchProfessionDetails, transformListToSingleData),
  ),
  createProfile: postQuery(PROFILES_ENDPOINT),
  createDigitalLinks: postQuery(PROFILE_CONTACTS_ENDPOINT),
  createProfessions: postQuery(PROFESSIONS),
  getProfileDocuments: getQuery('GET_PROFILE_DOCUMENTS', fetchProfileDocuments),
  uploadProfileDocuments: postQuery(PROFILE_DOCUMENTS),
  deleteProfileDocument: deleteQuery(DELETE_PROFILE_DOCUMENT),
  getFeaturedEntities: getQuery(
    'GET_FEATURED_ENTITIES',
    transformQueryFn(fetchFeaturedEntities, transformImagesWithNoIndex),
  ),
};

export default queries;
