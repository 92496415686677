/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useMemo } from 'react';
import _isEmpty from 'lodash-es/isEmpty';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import CircularLoader from 'components/uiLibrary/Loaders/CircularLoader';
import useTracking from 'components/Globals/Analytics';
import { Link as LinkTag } from 'src/i18n';
import { addProtocolIfNeed } from 'utils/common';

import { useRouter } from 'next/router';
import { useTrackingClickStyles } from 'components/Globals/TrackingTester/TrackingTester';
import new_classes from './LinkButton.module.scss';

const LinkButton = ({
  children,
  onClick,
  variant = 'primary',
  leftIcon,
  rightIcon,
  disabled = false,
  type = 'button',
  styles = { root: '', icon: '' },
  loading = false,
  isLink = false,
  href,
  target = '_self',
  rel,
  title,
  ariaLabel,
  stopPropagation,
  preventDefault,
  tabIndex = 0,
  external = false,
  download = false,
  active = false,
  disableUnderline = false,
  disableHover = false,
  overrideButtonStyles = false,
  inline = false,
  wrap = false,
  shape = 'rounded',
  size = 'large',
  isV4 = false,
  stretch = false,
  classes: _classes,
  skipTracking = false,
  trackingData,
  inlineStyle,
  ...rest
}) => {
  const trackingClasses = useTrackingClickStyles(trackingData, skipTracking);
  const router = useRouter();
  const track = useTracking();

  const loaderSize = useMemo(() => {
    if (!isV4) {
      return 20;
    }
    switch (size) {
      case 'large':
        return 20;
      case 'medium':
        return 16;
      case 'small':
        return 12;
      default:
        return 20;
    }
  }, [isV4, size]);
  const forceV4FromQuery = useMemo(() => {
    const query = router?.query;
    return !!query?.forceV4B;
  }, [router]);

  // TODO Remove once test feature is no longer needed
  const classes = useMemo(() => (forceV4FromQuery || isV4 ? new_classes : _classes), [
    _classes,
    forceV4FromQuery,
    isV4,
  ]);

  const handleClick = useCallback(
    event => {
      if (process.env.APP_ENV !== 'production' && !external && target === '_blank' && window.analyticsWindow) {
        const anchor = event.target.closest('a');
        if (anchor) {
          const hrefValue = anchor.getAttribute('href');

          if (hrefValue) {
            const targetWindow = window.open(anchor.getAttribute('href'), '_blank');

            if (targetWindow) {
              targetWindow.onload = () => {
                targetWindow.analyticsWindow = window.analyticsWindow;
              };
            }
          }
        }
      }

      if (preventDefault) {
        event.preventDefault();
      }
      if (stopPropagation) {
        event.stopPropagation();
      }

      if (typeof onClick === 'function') {
        onClick(event);
      }
      if (!skipTracking && !_isEmpty(trackingData)) {
        track.click(trackingData);
      }
    },
    [external, onClick, preventDefault, skipTracking, stopPropagation, target, track, trackingData],
  );

  const linkTitle = useMemo(() => title || (typeof children === 'string' ? children : null), [title, children]);

  const linkButtonContent = useMemo(
    () => (
      <>
        {leftIcon && (
          <span className={classnames(classes.icon, { [classes.leftIcon]: !!children, [styles.icon]: !!styles.icon })}>
            {leftIcon}
          </span>
        )}
        {wrap ? (
          <span
            className={classnames(classes.wrap, {
              [classes.left]: leftIcon && !rightIcon,
              [classes.right]: rightIcon && !leftIcon,
              [classes.center]: leftIcon && rightIcon,
            })}
          >
            {children}
          </span>
        ) : (
          <>{children}</>
        )}
        {rightIcon && (
          <span className={classnames(classes.icon, { [classes.rightIcon]: !!children, [styles.icon]: !!styles.icon })}>
            {rightIcon}
          </span>
        )}
        {loading && <CircularLoader classes={classes.loader} size={loaderSize} />}
      </>
    ),
    [leftIcon, styles.icon, children, rightIcon, loading, wrap, classes, loaderSize],
  );

  let genericClasses = '';

  if (variant === 'native') {
    genericClasses = classnames(classes.native, trackingClasses, {
      [styles.root]: !!styles.root,
    });
  } else {
    genericClasses = classnames(classes.btn, trackingClasses, {
      [classes[`shape__${shape}`]]: !!shape,
      [classes[`size__${size}`]]: !!size,
      [classes.stretched]: !!stretch,
      [classes[variant]]: variant,
      [classes.disabled]: disabled,
      [classes.active]: active,
      [classes.disableUnderline]: variant === 'text' && disableUnderline,
      [classes.disableHoverEffect]: variant === 'text' && disableHover,
      [classes.overrideButtonStyles]: overrideButtonStyles,
      [classes.inline]: inline,
      [classes.loading]: loading,
      [styles.root]: !!styles.root,
    });
  }

  if (!isLink) {
    return (
      <button
        className={genericClasses}
        {...(onClick && { onClick: handleClick })}
        disabled={disabled || loading}
        type={type}
        tabIndex={tabIndex}
        title={linkTitle}
        {...(inlineStyle && { style: inlineStyle })}
      >
        {linkButtonContent}
      </button>
    );
  }

  const linkProps = {
    title: linkTitle,
    className: classnames(genericClasses, {
      [classes.iconLink]: Boolean(leftIcon) || Boolean(rightIcon),
    }),
    target,
    rel,
    onClick: handleClick,
    'aria-label': ariaLabel,
    tabIndex,
    download,
    disabled: disabled || loading,
  };

  if (external) {
    const externalLink = rest?.url || rest?.as || href;

    return (
      <a
        {...linkProps}
        href={addProtocolIfNeed(externalLink)}
        target="_blank"
        rel={rel || 'nofollow'}
        {...(inlineStyle && { style: inlineStyle })}
      >
        {linkButtonContent}
      </a>
    );
  }

  if (!href) {
    return <>{children}</>;
  }

  return (
    <LinkTag href={href} prefetch={false} {...rest}>
      <a {...linkProps} {...(inlineStyle && { style: inlineStyle })}>
        {linkButtonContent}
      </a>
    </LinkTag>
  );
};

LinkButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onClick: PropTypes.func,
  variant: PropTypes.oneOf([
    'native',
    'primary',
    'secondary',
    'tertiary',
    'quaternary',
    'cuetv',
    'text',
    'orange',
    'grey',
    'overlay',
    'dark',
  ]),
  shape: PropTypes.oneOf(['rounded', 'rectangle']),
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  leftIcon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  rightIcon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  isLink: PropTypes.bool,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  target: PropTypes.string,
  rel: PropTypes.string,
  title: PropTypes.string,
  ariaLabel: PropTypes.string,
  stopPropagation: PropTypes.bool,
  tabIndex: PropTypes.number,
  external: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  disableHover: PropTypes.bool,
};

LinkButton.defaultProps = {
  disabled: false,
  variant: 'primary',
  type: 'button',
  isLink: false,
  rel: '',
  target: '_self',
  tabIndex: 0,
};

export default LinkButton;
