import { defaultProductionPagination } from '../../constants/consts';

export const GET_PROFILE_DATA = 'app/ArtistPage/GET_PROFILE_DATA';
export const SET_PROFILE = 'app/ArtistPage/SET_PROFILE';
export const GET_PROFILE_PRODUCTIONS = 'app/ArtistPage/GET_PROFILE_PRODUCTIONS';
export const GET_PROFILE_PERFORMANCES = 'app/ArtistPage/GET_PROFILE_PERFORMANCES';
export const GET_PROFILE_CHANGE_REQUEST = 'app/ArtistPage/GET_PROFILE_CHANGE_REQUEST';
export const GET_ARTIST_SUGGESTIONS = 'app/ArtistPage/GET_ARTIST_SUGGESTIONS';
export const GET_PROFILE_REPERTOIRE = 'app/ArtistPage/GET_PROFILE_REPERTOIRE';
export const GET_PROFILE_IMAGES = 'app/ArtistPage/GET_PROFILE_IMAGES';
export const GET_PROFILE_BANNER_IMAGES = 'app/ArtistPage/GET_PROFILE_BANNER_IMAGES';
export const GET_PROFILE_BIO = 'app/ArtistPage/GET_PROFILE_BIO';
export const GET_PROFILE_REVIEWS = 'app/ArtistPage/GET_PROFILE_REVIEWS';
export const GET_PROFILE_PRODUCTION_REVIEWS = 'app/ArtistPage/GET_PROFILE_PRODUCTION_REVIEWS';
export const SET_PROFILE_ACTIVE_VIDEO = 'app/ArtistPage/SET_PROFILE_ACTIVE_VIDEO';
export const GET_PROFILE_PRODUCTIONS_SEASONS = 'app/ArtistPage/GET_PROFILE_PRODUCTIONS_SEASONS';
export const GET_PROFILE_WORKS_LIST = 'app/ArtistPage/GET_PROFILE_WORKS_LIST';
export const CLEAR_PROFILE_WORKS_LIST = 'app/ArtistPage/CLEAR_PROFILE_WORKS_LIST';
export const START_SEARCHING_PROFILE_WORKS_LIST = 'app/ArtistPage/START_SEARCHING_PROFILE_WORKS_LIST';
export const GET_PROFILE_AGREEMENTS = 'app/ArtistPage/GET_PROFILE_AGREEMENTS';
export const GET_ARTIST_RECOMMENDATIONS = 'app/ArtistPage/GET_ARTIST_RECOMMENDATIONS';
export const GET_ARTIST_PROFESSIONS = 'app/ArtistPage/GET_ARTIST_PROFESSIONS';
export const GET_ARTIST_ROLES = 'app/ArtistPage/GET_ARTIST_ROLES';
export const GET_PRODUCTION_CAST = 'app/ArtistPage/GET_PRODUCTION_CAST';
export const UPDATE_REPERTOIRE_STATUS = 'app/ArtistPage/UPDATE_REPERTOIRE_STATUS';
export const UPDATE_REPERTOIRE_MODAL = 'app/ArtistPage/UPDATE_REPERTOIRE_MODAL';

export const UPDATE_PROFILE = 'app/ArtistPage/UPDATE_PROFILE';
export const GET_VIDEO = 'app/ArtistPage/GET_VIDEO';
export const GET_INTRO_SHOW_REELS_ACTION = 'app/ArtistPage/GET_INTRO_SHOW_REELS_ACTION';
export const GET_AUDITION_SHOW_REELS_ACTION = 'app/ArtistPage/GET_AUDITION_SHOW_REELS_ACTION';
export const GET_LSVOD = 'app/ArtistPage/GET_LSVOD';
export const ADD_VIDEO = 'app/ArtistPage/ADD_VIDEO';
export const UPDATE_VIDEO = 'app/ArtistPage/UPDATE_VIDEO';

export const UPDATE_BIO = 'app/ArtistPage/UPDATE_BIO';
export const ADD_BIO = 'app/ArtistPage/ADD_BIO';
export const GET_CONTACTS = 'app/ArtistPage/GET_CONTACTS';
export const UPDATE_CONTACTS = 'app/ArtistPage/UPDATE_CONTACTS';
export const ADD_REVIEW = 'app/ArtistPage/ADD_REVIEW';
export const UPDATE_REVIEW = 'app/ArtistPage/UPDATE_REVIEW';
export const REMOVE_PRODUCTION = 'app/ArtistPage/REMOVE_PRODUCTION_PRIVACY';
export const UPDATE_PRODUCTION = 'app/ArtistPage/UPDATE_PRODUCTION';
export const DELETE_PRODUCTION = 'app/ArtistPage/DELETE_PRODUCTION';
export const PUBLISH_PRODUCTION = 'app/ArtistPage/PUBLISH_PRODUCTION';
export const GET_DATES_IN_PRODUCTIONS = 'app/ArtistPage/GET_DATES_IN_PRODUCTIONS';
export const ADD_UPDATE_CONTACTS = 'app/ArtistPage/ADD_UPDATE_CONTACTS';
export const GET_PROFILE_REPERTOIRE_PRODUCTION = 'app/ArtistPage/GET_PROFILE_REPERTOIRE_PRODUCTION';
export const GET_PROFILE_REPERTOIRE_IMAGES = 'app/ArtistPage/GET_PROFILE_REPERTOIRE_IMAGES';
export const GET_PROFILE_REPERTOIRE_VIDEOS = 'app/ArtistPage/GET_PROFILE_REPERTOIRE_VIDEOS';
export const DELETE_BIO = 'app/ArtistPage/DELETE_BIO';
export const UPDATE_ARTIST_BANNER = 'app/ArtistPage/UPDATE_ARTIST_BANNER';
export const UPLOAD_ARTIST_BANNER = 'app/ArtistPage/UPLOAD_ARTIST_BANNER';
export const DELETE_IMAGE = 'app/ArtistPage/DELETE_IMAGE';
export const DELETE_VIDEO = 'app/ArtistPage/DELETE_VIDEO';
export const DELETE_REVIEW = 'app/ArtistPage/DELETE_REVIEW';
export const REMOVE_REPERTOIRE_RECORD = 'app/ArtistPage/REMOVE_REPERTOIRE_RECORD';
export const SET_PROFILE_PERFORMANCES = 'app/ArtistPage/SET_PROFILE_PERFORMANCES';
export const GET_ARTIST_PRODUCTIONS = 'app/ArtistPage/GET_ARTIST_PRODUCTIONS';
export const GET_VIDEO_FORMAT_TYPES = 'app/ArtistPage/GET_VIDEO_FORMAT_TYPES';
export const UPDATE_REPERTOIRE = 'app/ArtistPage/UPDATE_REPERTOIRE';
export const GET_IMAGE_FORMAT_TYPES = 'app/ArtistPage/GET_IMAGE_FORMAT_TYPES';
export const UPDATE_PRODUCTION_PRIVACY = 'app/ArtistPage/UPDATE_PRODUCTION_PRIVACY';
export const GET_PROFILE_PRODUCTION_IMAGES = 'app/ArtistPage/GET_PROFILE_PRODUCTION_IMAGES';
export const GET_PROFILE_PRODUCTION_VIDEOS = 'app/ArtistPage/GET_PROFILE_PRODUCTION_VIDEOS';

export const PROFILE_ACTIONS = 'app/ArtistPage/PROFILE_ACTIONS';
export const REVIEW_ACTIONS = 'app/ArtistPage/REVIEW_ACTIONS';

export const defaultArtistProductionsPagination = defaultProductionPagination;
export const ARTIST_AGENCY_ACTIONS = 'app/ArtistPage/AGENCY_ACTIONS';
export const GET_ARTIST_TRANSLATIONS = 'app/ArtistPage/GET_ARTIST_TRANSLATIONS';
export const UPDATE_ARTIST_TRANSLATIONS = 'app/ArtistPage/UPDATE_ARTIST_TRANSLATIONS';
export const DELETE_ARTIST_TRANSLATIONS = 'app/ArtistPage/DELETE_ARTIST_TRANSLATIONS';

// EDIT

export const artistEditTabValues = {
  overview: 'overview',
  personal: 'personal',
  contactInfo: 'contact-info',
  digitalPresence: 'digital-presence',
  schedule: 'schedule',
  musicalWorks: 'musical-works',
  repertoire: 'repertoire',
  videos: 'videos',
  photos: 'photos',
  reviews: 'reviews',
  biography: 'biography',
  draftsSchedule: 'draftsSchedule',
};

export const artistEditProTabs = [
  artistEditTabValues.digitalPresence,
  artistEditTabValues.repertoire,
  artistEditTabValues.videos,
  artistEditTabValues.photos,
  artistEditTabValues.reviews,
  artistEditTabValues.biography,
];
