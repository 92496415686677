import React from 'react';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import LinkButton from './LinkButton';

import legacy_classes from './LinkButtonOld.module.scss';
import classes from './LinkButton.module.scss';

export const PrimaryButton = props => <LinkButton {...props} variant="primary" classes={classes} />;
export const SecondaryButton = props => <LinkButton {...props} variant="secondary" classes={classes} />;
export const TertiaryButton = props => <LinkButton {...props} variant="tertiary" classes={classes} />;
export const QuaternaryButton = props => <LinkButton {...props} variant="quaternary" classes={classes} />;
export const DarkButton = props => <LinkButton {...props} variant="dark" classes={classes} />;
export const MediaWidgetButton = props => (
  <LinkButton
    {...props}
    variant="dark"
    size="medium__compact"
    classes={classes}
    leftIcon={<SpriteIcon icon="play_icon" size={24} />}
    styles={{ root: classes.mediaWidgetPadding }}
  />
);

export default props => <LinkButton classes={legacy_classes} {...props} />;
